

export default function Recrutement() {


    return <div style={{textAlign : "left"}}>

        <h1>Recrutement et passation</h1>
        <p>
            Le recrutement et la passation sont des processus essentiels au Rezal, en effet il est vital de pouvoir assurer la continuité des connaissance sur le Réseau et de permettre aux futures générations de meushards de disposer d'une connexion acceptable.
        </p>
        <h2>Quand cela se passe ?</h2>
        <p>
            Ces processus commencent généralement au début du mois de février, cela permet de laisser assez de temps pour l'association actuelle pour former correctement la future équipe. <br/> 
            Une des valeurs de l'association est son ouverture, il est donc encouragé que n'importe qui suive ces formations, même sans vouloir faire partie de l'association ensuite. Cela permet au Rézal de toucher plus de personnes et pour vous d'en apprendre toujours plus sur ce monde parfois méconnu !
        </p>
        <h2>
            Comment cela se passe
        </h2>
        <p>
            Généralement, quelques membres volontaires du Rézal de l'année préparent des formations pour expliquer aux volontaires les différents aspects du réseau et les fondamentaux.  <br/> 
            Cela ne constitue pas de cours sur les réseaux à proprement parler, mais on peut y voir une approche par l'expérience que les membres du Rézal transmettent. Ces formations durent d'un à deux mois et aboutissent sur une équipe prête à affronter les multiples problèmes pouvant occurir sur le réseau, en garde !
        </p>

    </div>
}