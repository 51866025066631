import {Box} from "@mui/material";
import './Footer.scss'

export function Footer() {
    
    return <footer id="footer">
        <Box bgcolor="text.secondary" style={{textAlign : "center", padding:"2vh"}}>
            <span>Rezal © 2021</span>
        </Box>
    </footer> 
}