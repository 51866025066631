

export default function Equipement() {


    return <div style={{textAlign : "left"}}>

        <h1>Prêts d'équipements</h1>
        <p>
            L'association dispose de quelques équipements qu'elle peut prêter durant une certaine durée en échange d'une caution. Ces équipements comprennent:
            <ul>
                <li>Des bornes d'appoint en cas de défaillance ou faiblesse <b>avérée et répétée</b> de la connexion</li>
            </ul>
        </p>
    </div>
}