

export default function Contact() {


    return <div style={{textAlign : "left"}}>

        <h2>Contact</h2>

        <p>
            Les membres de l'association sont disponibles via une adresse email d'administration : <i>admin@rezal-mdm.com</i>. Nous vous répondrons dans les meilleurs délais  
            Vous pouvez aussi nous contacter de manière plus informelle, chaque membre est alors libre de sa décision de vous répondre ou non !
        </p>
    
    </div>
}

