
///////////////////////////////////////////////////////////////////////////////////////////////
// The first value of each list is used if field is not provided to the account constructor ///
///////////////////////////////////////////////////////////////////////////////////////////////

export const promotions = [
    "Pas de promo",
    "Inconnue",
    "P24",
    "P23",
    "P22",
    "P21",
    "P20",
    "P19",
    "P18",
    "IsupP21",
    "IsupP20",
    "IsupP19",
    "IsupP18",
    "Corps",
    "Autre"
]

export const universities = [
    "Inconnue",
    "Exte",
    "Mines",
    "Ponts",
    "Ensta",
    "Autre"
]

export const paymentTypes = [
    "Non paye",
    "HelloAsso",
    "Inconnue",
    "Liquide",
    "Cheque",
    "Exonere",
    "Autre",
]
