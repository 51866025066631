import charte from "../../assets/doc/Charte_VF.pdf";
import './../AboutUs.scss'

export default function Equipe() {


    return <div style={{textAlign : "left"}}>

        <h1>Equipe</h1>
        <p>
            Cette année, nous somme 10 personnes au Rézal. L'asciation selon nos status actuels, est dirigée par un bureau restreint constitué de 3 personnes, ainsi que de membres d'un conseil d'administration, constitué des autres personnes de l'association. Les cotisants ont eu un statut de <i>simple membre</i>, ce qui leur permet de prendre part à l'assemblée générale de passation et à ses votes.
        </p>
        <h2>Le bureau restreint</h2>
        <p>
            Ce bureau est constitué par :
            <ul>
                <li>Un.e Président.e :actuellement Samy SMAIL (108)</li>
                <li>Un.e Trésorier.e : actuellement Antoine CHAPELANT </li>
                <li>Un.e Secrétaire Général: actuellement Sioban Nieradzik-Kozic </li>
            </ul>
        </p>
        <h2>Conseil d'administration</h2>
        <p>
            Le conseil d'administration est actuellement constitué de 4 personnes (par ordre alphabétique):
            <ul>
                <li>Yannis BARON </li>
                <li>Antoine CHAPELANT (Trésorier) </li>
                <li>Sioban Nieradzik-Kozic (Secrétaire �nérale) </li>
		<li>Samy SMAIL (Président) </li>
            </ul>
        </p>
        <h2>Simples membres</h2>
        <p>
            Pour devenir simple membre de l'association, il faut avoir créé un compte sur ce site, avoir lu et accepté la <a className="link" href={charte} rel="noreferrer" target = "_blank">charte d'utilisation du réseau</a> et avoir cotisé durant au moins un trimestre dans l'année!
        </p>
    </div>
}
